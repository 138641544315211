// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-product-js": () => import("./../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-accessories-js": () => import("./../src/templates/accessories.js" /* webpackChunkName: "component---src-templates-accessories-js" */),
  "component---src-templates-services-js": () => import("./../src/templates/services.js" /* webpackChunkName: "component---src-templates-services-js" */),
  "component---src-templates-category-list-js": () => import("./../src/templates/category-list.js" /* webpackChunkName: "component---src-templates-category-list-js" */),
  "component---src-templates-sub-category-list-js": () => import("./../src/templates/sub-category-list.js" /* webpackChunkName: "component---src-templates-sub-category-list-js" */),
  "component---src-templates-usage-list-js": () => import("./../src/templates/usage-list.js" /* webpackChunkName: "component---src-templates-usage-list-js" */),
  "component---src-templates-sub-usage-list-js": () => import("./../src/templates/sub-usage-list.js" /* webpackChunkName: "component---src-templates-sub-usage-list-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-500-js": () => import("./../src/pages/500.js" /* webpackChunkName: "component---src-pages-500-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-help-js": () => import("./../src/pages/help.js" /* webpackChunkName: "component---src-pages-help-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-offer-js": () => import("./../src/pages/offer.js" /* webpackChunkName: "component---src-pages-offer-js" */),
  "component---src-pages-service-js": () => import("./../src/pages/service.js" /* webpackChunkName: "component---src-pages-service-js" */)
}

